import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const HomeOne = lazy(() => import("./home/HomeOne"));
const HomeTwo = lazy(() => import("./home/HomeTwo"));
const HomeThree = lazy(() => import("./home/HomeThree"));
const About = lazy(() => import("./pages/About"));
const Services = lazy(() => import("./service/Services"));
const Appzoneamento = lazy(() => import("./service/appzoneamento"));
const SejaConsultor = lazy(() => import("./service/formularioConsultor"));
const SejaCliente = lazy(() => import("./service/formularioGenerico"));
const Processos = lazy(() => import("./service/processos"));
const AppUteis = lazy(() => import("./service/appUteis"));
const Whatsapp = lazy(() => import("./service/whatsapp"));
const ServiceDetailsLeftSidebar = lazy(() => import("./service/ServiceDetailsLeftSidebar"));
const ServiceDetailsRightSidebar = lazy(() => import("./service/ServiceDetailsRightSidebar"));
const Projects = lazy(() => import("./project/Projects"));
const ProjectDetails = lazy(() => import("./project/ProjectDetails"));
const BlogLeftSidebar = lazy(() => import("./blog/BlogLeftSidebar"));
const BlogRightSidebar = lazy(() => import("./blog/BlogRightSidebar"));
const BlogDetailsLeftSidebar = lazy(() => import("./blog/BlogDetailsLeftSidebar"));
const BlogDetailsRightSidebar = lazy(() => import("./blog/BlogDetailsRightSidebar"));
const Contact = lazy(() => import("./pages/Contact"));
const Tcgu = lazy(() => import("./pages/tcgu"));

const App = () => {
  return (
    <Router>
      <Suspense fallback={<div />}>
        <Routes>
          <Route path="/" element={<HomeOne />} />
          <Route path="/home" element={<HomeOne />} />                
          <Route path="/qs" element={<About />} />
          <Route path="/equipe" element={<HomeTwo />} />
          <Route path="/home-three" element={<HomeThree />} />
          <Route path="/quemsomos" element={<About />} />
          <Route path="/capacitacao" element={<Services />} />
          <Route path="/appzoneamento" element={<Appzoneamento />} />
          <Route path="/seja-consultor" element={<SejaConsultor />} />
          <Route path="/cliente" element={<SejaCliente />} />
          <Route path="/processos" element={<Processos />} />
          <Route path="/Links-uteis" element={<AppUteis />} />
          <Route path="/whatsapp" element={<Whatsapp />} />          
          <Route path="/service-details-left-sidebar" element={<ServiceDetailsLeftSidebar />} />
          <Route path="/service-details-right-sidebar" element={<ServiceDetailsRightSidebar />} />
          <Route path="/buscadeprocessos" element={<Projects />} />
          <Route path="/project-details" element={<ProjectDetails />} />
          <Route path="/blog-left-sidebar" element={<BlogLeftSidebar />} />
          <Route path="/blog-right-sidebar" element={<BlogRightSidebar />} />
          <Route path="/news" element={<BlogDetailsLeftSidebar />} />             
          <Route path="/blog-details-right-sidebar" element={<BlogDetailsRightSidebar />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/tcgu" element={<Tcgu />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;